export const betaServerURL = "";
export const devServerURL = "";
export const stgServerURL = "";
export const prodServerUrl = "";

export const devChatServerIP = "";
export const stgChatServerIP = "";
export const prodChatServerIP = "";

export const devBucketURL = "";
export const stgBucketURL = "";
export const prodBucketURL = "";

export const defaultServer = stgServerURL;
export const API_TIMEOUT = 60000;

export const API = "https://dashlink.dashline.tech";

// export const kApiLogout = (accessToken) => {
//   return `${API}/users/logout?access_token=${accessToken}`;
// };

export const kApiCommonSettings = `${API}/common/settings`;

export const kApiSignup = `${API}/user/signup`;
export const kApiSocialSignup = `${API}/auth/social-signup`;
export const kApiLogin = `${API}/user/login`;
export const kApiGetAllUsers = `${API}/user/me`;
export const kApiAddNewLink = `${API}/link/newlink`;
export const kApiGetLinkList = `${API}/link/list`;

export const API_LOG = false;

export const ERROR_SOMETHING_WENT_WRONG = {
  title: "Oops!",
  message: "Unexpected error! Looks like we really need to look into this.",
  error: 1,
};

export const ERROR_NETWORK_NOT_AVAILABLE = {
  title: "Oops!",
  message:
    "Slow or no internet connection. Please check your internet settings.",
  error: 1,
};

export const ERROR_UNAUTHORIZED = {
  title: "Oops!",
  message: "Either you are not logged in or your session seem to be expired.",
  error: 1,
};

export const ERROR_REQUEST_TIMEOUT = {
  title: "Server coming slow!",
  message:
    "Looks like the server is taking too long to respond, please try again after a while.",
  error: 1,
};

export const ERROR_NOT_FOUND = {
  title: "Not on this earth!",
  message:
    "Looks like either the API or the server for this request doesnot exist.",
  error: 1,
};
