import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "../Pages/app/Home";
import Login from "../Pages/auth/Login";

export default function ReactDOM() {
  const isToken = useSelector((state) => state.login.data);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" caseSensitive={false} element={<Login />} />
        <Route path="/home" caseSensitive={false} element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}
