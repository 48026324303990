import { combineReducers } from "redux";
// import general from "./general";
import signUpUses from "./signUpUses";
import login from "./login";
import getAllUser from "./getAllUser";
import addNewLink from "./addNewLink";
import getLinkList from "./getLinkList";

export default combineReducers({
  // general,
  signUpUses,
  login,
  getAllUser,
  addNewLink,
  getLinkList,
});
