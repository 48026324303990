import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { logout } from "../../../store/actions/loginA";
import { useNavigate } from "react-router-dom";
import {
  API,
  kApiGetAllUsers,
  kApiGetLinkList,
} from "../../../config/WebService";
import { request as getAllReq } from "../../../store/actions/getAllUsersA";
import { request as getLinkListReq } from "../../../store/actions/getLinkListA";
import CustomModal from "../../../components/CustomModal";
import QrCode from "../../../assets/Qr.png";
import whiteQrCode from "../../../assets/qr-code.png";
import QRCode from "react-qr-code";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ownData = useSelector((state) => state.getAllUser.data);
  const getLinkListData = useSelector((state) => state.getLinkList.data);
  const [isModal, setModal] = useState(false);

  useEffect(() => {
    dispatch(getAllReq(kApiGetAllUsers, {}));
    dispatch(getLinkListReq(kApiGetLinkList, {}));
  }, []);

  const logoutUser = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    navigate("/");
  };

  const onImageCownload = (qrid) => {
    const svg = document.getElementById(qrid);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const getLinkListFuncView = () => {
    return getLinkListData?.links?.map((i) => {
      return (
        <Row className="qrList p-4 bg-white row align-items-center justify-content-center mb-4 rounded-4">
          <Col
            lg={6}
            className="d-flex flex-wrap align-items-center mb-4 mb-lg-0"
          >
            <img
              src={QrCode}
              width={58}
              height={58}
              className="object-fit-contain me-3"
            />
            <div className="textDevStyle">
              <p className="fs-6 fw-bold mb-0">Link</p>
              <a
                target={"_blank"}
                className="fs-3 fw-normal primary-color text-decoration-none"
                href={`${API}/qr/urlredirect?urlcode=` + i.urlcode}
              >
                {i.linkname}
              </a>
            </div>
          </Col>

          <Col lg={6} className="text-end">
            <input
              type="button"
              className="custom-btn secondary-btn me-4"
              value="Download"
              onClick={() => onImageCownload(i.urlcode)}
            />
            <QRCode
              size={256}
              style={{ height: "auto", width: "100%", maxWidth: "113px" }}
              value={`${API}/qr/urlredirect?urlcode=` + i.urlcode}
              viewBox={`0 0 256 256`}
              id={i.urlcode}
            />
          </Col>
        </Row>
      );
    });
  };
  return (
    <Container fluid>
      <CustomModal show={isModal} onHide={() => setModal(false)} />
      <Row className="min-vh-100">
        <Col md={3} lg={2} className="secondary-bg p-0">
          <div className="userProfile d-flex flex-wrap align-items-center justify-content-center w-100 mx-auto">
            <div className="userAvatar d-flex align-items-center justify-content-center secondary-color text-uppercase rounded-circle me-3">
              {ownData.username?.substring(0, 2)}
            </div>
            <div className="userInfo w-100">
              <div className="text-white text-capitalize">
                {ownData.username}
              </div>
              <div className="text-white userEmail">
                {ownData.email?.substring(0, 10)}...
              </div>
            </div>
          </div>
          <ul className="sideBar list-unstyled mb-0">
            <li className="d-block w-100 text-start">
              <Button
                type="button"
                className="text-white text-capitalize bg-transparent border-0 p-0"
              >
                <img
                  src={whiteQrCode}
                  alt="Image"
                  className="me-2"
                  width={18}
                  height={18}
                />
                QR Codes
              </Button>
            </li>
            <li className="d-block w-100 text-start">
              <Button
                type="button"
                onClick={() => logoutUser()}
                className="text-white text-capitalize bg-transparent border-0 p-0"
              >
                <img
                  src={whiteQrCode}
                  alt="Image"
                  className="me-2"
                  width={18}
                  height={18}
                />
                Logout
              </Button>
            </li>
          </ul>
        </Col>
        <Col md={9} lg={10} className="light-bg p-0">
          <h4 className="bg-white fw-bold fs-4 p-4 mb-4">QR Codes</h4>
          <div className="px-4">
            <div
              className="createQR text-center d-flex align-items-center justify-content-center mb-4 mb-md-5"
              onClick={() => setModal(true)}
            >
              <h2 className="fs-2 fw-normal mb-0 primary-color">
                + Create QR code
              </h2>
            </div>

            {getLinkListFuncView()}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
