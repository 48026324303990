class ErrorHelper {
  handleErrors(err, doAlert) {
    if (doAlert && !err.doIgnore) {
      const errObj = err && err.message ? err : err.error;
      const errName =
        errObj && (errObj.name || errObj.title)
          ? errObj.name || errObj.title
          : "Error!";

      let errMsg;

      if (errObj?.errors && Object.keys(errObj.errors).length > 0) {
        const firstKey = Object.keys(errObj.errors)[0];

        const errorArray = errObj.errors[firstKey];

        if (
          errorArray &&
          errorArray.length > 0 &&
          typeof errorArray[0] === "string"
        ) {
          errMsg = errorArray[0];
        } else {
          errMsg = errObj && errObj.message ? errObj.message : "";
        }
      } else {
        errMsg = errObj && errObj.message ? errObj.message : "";
      }

      if (errMsg && errMsg.length > 0) {
        alert(errName, errMsg);
      }
    }
  }
}

export default new ErrorHelper();
