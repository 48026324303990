import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { API, kApiAddNewLink, kApiGetLinkList } from "../config/WebService";
import { request as addNewLinkReq } from "../store/actions/AddNewLinkA";
import { request as getLinkListReq } from "../store/actions/getLinkListA";
import { nanoid } from 'nanoid';

function CustomModal({ show, onHide }) {
  const [stateUrl, setUrl] = useState(null);
  const [stateId, setID] = useState(null);
  const [linkName, setLinkName] = useState(null);
  const dispatch = useDispatch();

  function ValidURL() {
    var regex =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(stateUrl)) {
      alert("Please enter valid URL.");
      return false;
    } else {
      return true;
    }
  }

  const onSubmit = () => {
    if (!stateUrl) {
      return alert("Enter Url");
    }
    if (!ValidURL()) return;
    if (!stateId) {
      return alert("Enter ID");
    }
    if (!linkName) {
      return alert("Enter Name");
    }
    let payload = {
      urlcode: stateId,
      url: stateUrl,
      linkname: linkName
    };
    dispatch(addNewLinkReq(kApiAddNewLink, payload));
    onHide();
    setUrl(null);
    setID(null);
    setLinkName(null);

    setTimeout(() => {
      dispatch(getLinkListReq(kApiGetLinkList, {}));
    }, 1000);
  };

  const onImageCownload = (qrid) => {
    if (!stateUrl) {
      return alert("Enter Url");
    }
    if (!ValidURL()) return;
    if (!stateId) {
      return alert("Enter ID");
    }
    const svg = document.getElementById(qrid);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const handleChange = (event) => {
    setID(nanoid());
    setUrl(event.target.value);
  }

  const textInputs = () => {
    return (
      <div className="form-main">
        <div className="form-field mb-3">
          <Form.Label className="fw-normal mb-1">Name</Form.Label>
          <Form.Control
            className="border border-1 rounded-3 shadow-none primary-color"
            onChange={(v) => setLinkName(v.target.value)}
            required
          />
        </div>

        <div className="form-field mb-3">
          <Form.Label className="fw-normal mb-1">Redirect URL</Form.Label>
          <Form.Control
            type="url"
            className="border border-1 rounded-3 shadow-none primary-color"
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-field mb-3">
          <Form.Label className="fw-normal mb-1">Link URL</Form.Label>
          <Form.Control
            disabled
            className="border border-1 rounded-3 shadow-none primary-color"
            // onChange={(v) => setID(v.target.value)}
            value={
              stateUrl === null || stateId === null
                ? ""
                : `${API}/qr/urlredirect?urlcode=` + stateId
            }
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center position-relative">
        <Modal.Title className="fs-4 fw-normal px-5 text-center">
          Create QR code
        </Modal.Title>
        <button
          type="button"
          onClick={() => {
            onHide();
            setUrl(null);
            setID(null);
          }}
          class="btn-close light-bg rounded-3 opacity-1 position-absolute end-0 ms-0 me-3"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body className="py-4 px-3">
        <Container>
          <Row>
            <Col lg={7} className="mb-lg-0 mb-4">
              <div className="px-3 py-2">
                <QRCode
                  style={{ height: "auto", width: "100%" }}
                  value={`${API}/qr/urlredirect?urlcode=` + stateId}
                  viewBox={`0 0 256 256`}
                  id={stateId}
                />
              </div>
              <Row className="m-0 justify-content-center mt-lg-5 mt-3">
                <Col lg={9} className="text-center">
                  <input
                    type="button"
                    value="Download"
                    onClick={() => onImageCownload(stateId)}
                    className="w-100 custom-btn primary-btn"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={5} className="d-flex flex-wrap">
              <div className="w-100">{textInputs()}</div>
              <Button
                onClick={() => onSubmit()}
                className="w-100 custom-btn secondary-btn align-self-end"
              >
                Done
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
