import { take, put, call, fork } from "redux-saga/effects";

import _ from "lodash";

import ApiSauce from "../../services/ApiSauce";
import { success, failure } from "../actions/getLinkListA";
import { kApiLogin } from "../../config/WebService";
import * as types from "../actions/ActionsType";

import ErrorHelper from "../../helpers/ErrorHelper";

function callDeleteRequest(url, data, headers) {
  return ApiSauce.delete(url, data, headers);
}

function callGetRequest(url, data, headers) {
  return ApiSauce.get(url, data, headers);
}

function callPostRequest(url, data, headers) {
  return ApiSauce.post(url, data, headers);
}

function* watchRequest() {
  while (true) {
    const { url, type, payload } = yield take(types.GET_LINK_LIST.REQUEST);
    try {
      let response;

      response = yield call(callGetRequest, url, payload);
      console.log(".........................", response);
      yield put(success(response));
    } catch (err) {
      yield put(failure(err.msg));

      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
