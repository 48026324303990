import { SIGN_UP_CLEAR, SIGN_UP_USER } from "./ActionsType";

export function request(url, payload) {
  return {
    url,
    payload,
    type: SIGN_UP_USER.REQUEST,
  };
}

export function success(payload) {
  return {
    payload,
    type: SIGN_UP_USER.SUCCESS,
  };
}

export function clearSignup() {
  return {
    type: SIGN_UP_CLEAR,
  };
}

export function failure(payload) {
  return {
    payload,
    type: SIGN_UP_USER.FAILURE,
  };
}
