import { fork } from "redux-saga/effects";
// import general from './general';

import signUpUser from "./signUpUserS";
import login from "./loginS";
import getAllUser from "./getAllUserS";
import addNewLink from "./addNewLinkS";
import getLinkList from "./getLinkListS";

export default function* root() {
  yield fork(signUpUser);
  yield fork(login);
  yield fork(getAllUser);
  yield fork(addNewLink);
  yield fork(getLinkList);
}
