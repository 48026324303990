import { LOGIN_USER_INFO, LOGOUT } from "./ActionsType";

export function request(url, payload) {
  return {
    url,
    payload,
    type: LOGIN_USER_INFO.REQUEST,
  };
}

export function success(payload) {
  return {
    payload,
    type: LOGIN_USER_INFO.SUCCESS,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function failure(payload) {
  return {
    payload,
    type: LOGIN_USER_INFO.FAILURE,
  };
}
