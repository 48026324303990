import { GET_ALL_USER, LOGIN_USER_INFO } from "./ActionsType";

export function request(url, payload) {
  return {
    url,
    payload,
    type: GET_ALL_USER.REQUEST,
  };
}

export function success(payload) {
  return {
    payload,
    type: GET_ALL_USER.SUCCESS,
  };
}
export function failure(payload) {
  return {
    payload,
    type: GET_ALL_USER.FAILURE,
  };
}
