import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  clearSignup,
  request as signUpReq,
} from "../../../store/actions/signUpA";
import { request as signInReq } from "../../../store/actions/loginA";

import { useDispatch, useSelector } from "react-redux";
import {
  kApiGetAllUsers,
  kApiLogin,
  kApiSignup,
} from "../../../config/WebService";

function Login() {
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);
  const [fullN, setfullN] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isSignup, setIsSignup] = useState(false);
  const dispatch = useDispatch();
  let isSignUpSuccess = useSelector((state) => state.signUpUses.data);
  let isToken = useSelector((state) => state.login.data);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignUpSuccess) {
      setIsSignup(false);
      dispatch(clearSignup());
    }
  }, [isSignUpSuccess]);

  useEffect(() => {
    if (isToken?.length) {
      navigate("/home");
    }
  }, [isToken]);

  const onSubmitLogin = () => {
    if (!email) {
      return alert("Enter email");
    }
    if (!pass) {
      return alert("Enter passowrd");
    }

    let payload = {
      email: email,
      password: pass,
    };

    dispatch(signInReq(kApiLogin, payload));
  };

  const onSubmitSignUp = () => {
    if (!userName) {
      return alert("Enter Username");
    }
    if (!email) {
      return alert("Enter email");
    }
    if (!pass) {
      return alert("Enter passowrd");
    }

    let payload = {
      username: userName,
      email: email,
      password: pass,
    };

    dispatch(signUpReq(kApiSignup, payload));
  };

  const signUpSection = () => {
    return isSignup ? (
      <div className="form-main">
        <div className="form-field mb-3">
          <Form.Label className="fw-normal mb-1">Full name</Form.Label>
          <Form.Control
            type="text"
            className="border border-1 rounded-3 shadow-none primary-color"
            onChange={(v) => setfullN(v.target.value)}
            required
          />
        </div>

        <div className="form-field mb-3">
          <Form.Label className="fw-normal mb-1">Username</Form.Label>
          <Form.Control
            type="text"
            className="border border-1 rounded-3 shadow-none primary-color"
            onChange={(v) => setUserName(v.target.value)}
            required
          />
        </div>
      </div>
    ) : null;
  };

  return (
    <Container fluid className="login-section">
      <Row className="signUp-row min-vh-100">
        <Col
          md={6}
          className="logo secondary-bg d-flex align-items-center justify-content-center py-3"
        >
          <div className="text-white fs-2 fw-bold">Logo</div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center flex-wrap py-5 light-bg"
        >
          <div className="login-form w-100">
            <h2 className="signInTextStyle fs-2 secondary-color fw-bold text-center text-capitalize mb-5">
              {isSignup ? "Sign up" : "Sign in"}
            </h2>
            {signUpSection()}
            <div className="form-main">
              <div className="form-field mb-3">
                <Form.Label className="fw-normal mb-1">Email</Form.Label>
                <Form.Control
                  type="text"
                  className="border border-1 rounded-3 shadow-none primary-color"
                  onChange={(v) => setEmail(v.target.value)}
                  required
                />
              </div>
              <div className="form-field mb-3">
                <Form.Label className="fw-normal mb-1">Password</Form.Label>
                <Form.Control
                  type="password"
                  className="border border-1 rounded-3 shadow-none primary-color"
                  onChange={(v) => setPass(v.target.value)}
                  required
                />
              </div>
            </div>

            <div className="mt-5">
              <Button
                className="secondary-btn custom-btn w-100"
                variant="primary"
                onClick={() => (isSignup ? onSubmitSignUp() : onSubmitLogin())}
                type="submit"
              >
                Done
              </Button>
            </div>
            <div className="text-center mt-3">
              <p className="fw-normal secondary-color m-0">
                Don’t have an account?{" "}
                <span
                  className="primary-color"
                  onClick={() => setIsSignup(!isSignup)}
                  role="button"
                >
                  {isSignup ? "Sign in" : "Sign up"}
                </span>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
