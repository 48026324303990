import { take, put, call, fork } from "redux-saga/effects";

import _ from "lodash";

import ApiSauce from "../../services/ApiSauce";
import { success, failure } from "../actions/loginA";
import { kApiLogin } from "../../config/WebService";
import * as types from "../actions/ActionsType";

import ErrorHelper from "../../helpers/ErrorHelper";

function callDeleteRequest(url, data, headers) {
  return ApiSauce.delete(url, data, headers);
}

function callGetRequest(url, data, headers) {
  return ApiSauce.get(url, data, headers);
}

function callPostRequest(url, data, headers) {
  return ApiSauce.post(url, data, headers);
}

function* watchRequest() {
  while (true) {
    const { url, type, payload } = yield take(types.LOGIN_USER_INFO.REQUEST);
    try {
      let response;

      response = yield call(callPostRequest, url, payload);
      if (response.token) {
        localStorage.setItem("token", response.token);
        yield put(success(response?.token));
      }
    } catch (err) {
      alert(err?.errors?.length ? err?.errors?.map((i) => i.msg) : err.msg);
      yield put(failure(err.msg));

      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
