// @flow
import { create } from "apisauce";
import { take, fork, select } from "redux-saga/effects";

import {
  API_LOG,
  API_TIMEOUT,
  ERROR_SOMETHING_WENT_WRONG,
  ERROR_NETWORK_NOT_AVAILABLE,
  ERROR_REQUEST_TIMEOUT,
  ERROR_UNAUTHORIZED,
  ERROR_NOT_FOUND,
} from "../config/WebService";
// import { DataHelper } from "../helpers";

// getUpdatedHeader = async (headers) => {
//   let token = await localStorage.getItem("token");
//   if (token) {
//     return {
//       ...headers,
//       Authorization: "Bearer " + token,
//     };
//   } else {
//     return headers;
//   }
// };

const baseUrl = "";
const api = create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "accept-version": "0.1",
  },
  timeout: API_TIMEOUT,
});

class ApiSauce {
  async post(url, data, headers) {
    const updatedHeader = await localStorage.getItem("token");

    const response = await api.post(url, data, {
      headers: {
        token: updatedHeader,
      },
    });

    if (API_LOG) {
    }

    return new Promise((resolve, reject) => {
      this.handlePromise(resolve, reject, response);
    });
  }
  async postImageUpdate(url, data, headers) {
    const updatedHeader = await localStorage.getItem("token");

    const response = await api.post(url, data, {
      headers: {
        token: updatedHeader,
        "Content-Type": "multipart/form-data",
      },
    });

    if (API_LOG) {
    }

    return new Promise((resolve, reject) => {
      this.handlePromise(resolve, reject, response);
    });
  }
  async put(url, data, headers) {
    const updatedHeader = await localStorage.getItem("token");

    const response = await api.put(url, data, {
      headers: {
        token: updatedHeader,
      },
    });

    if (API_LOG) {
    }

    return new Promise((resolve, reject) => {
      this.handlePromise(resolve, reject, response);
    });
  }

  async delete(url, data, headers) {
    const updatedHeader = await localStorage.getItem("token");

    const response = await api.delete(url, data, {
      headers: {
        token: updatedHeader,
      },
    });

    if (API_LOG) {
    }

    return new Promise((resolve, reject) => {
      this.handlePromise(resolve, reject, response);
    });
  }

  async get(url, data, headers) {
    const updatedHeader = await localStorage.getItem("token");
    const response = await api.get(url, data, {
      headers: {
        token: updatedHeader,
      },
    });
    if (API_LOG) {
    }

    return new Promise((resolve, reject) => {
      this.handlePromise(resolve, reject, response);
    });
  }

  async postImage(url, data, headers) {
    const updatedHeader = await localStorage.getItem("token");

    const response = await api.post(url, data, { headers: updatedHeader });

    if (API_LOG) {
    }

    return new Promise((resolve, reject) => {
      this.handlePromise(resolve, reject, response);
    });
  }

  changeUrl(newURL: String) {
    api.setBaseURL(newURL);
  }

  handlePromise = (resolve, reject, response) => {
    let customErrorParam;

    if (response.ok && response.data && !response.data.error) {
      resolve(response.data);
    } else {
      if (
        (response.data &&
          response.data.error &&
          response.data.error.code &&
          response.data.error.code === "AUTHORIZATION_REQUIRED") ||
        response?.data?.message === "Unauthenticated."
      ) {
        reject(ERROR_UNAUTHORIZED);

        // DataHelper.onLogout();
      }
      if (response.problem === "NETWORK_ERROR") {
        reject(ERROR_NETWORK_NOT_AVAILABLE);
      }
      if (response.problem === "TIMEOUT_ERROR") {
        reject(ERROR_REQUEST_TIMEOUT);
      }
      if (response.status === 500) {
        reject(ERROR_SOMETHING_WENT_WRONG);
      }
      if (response.status === 404) {
        reject(ERROR_NOT_FOUND);
      }
      if (response.status === 406) {
        alert(response?.data?.message, response?.data?.data?.errors);

        customErrorParam = { doIgnore: true };
      }
      if (response.status === 412) {
        alert("", "Update version");
        reject();
      }
      reject(customErrorParam || response.data || response);
    }
  };
}

export default new ApiSauce();
