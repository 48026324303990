// @flow
const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const CANCEL = "CANCEL";

function createRequestTypes(base) {
  const res = {};
  [REQUEST, SUCCESS, FAILURE, CANCEL].forEach((type) => {
    res[type] = `${base}_${type}`;
  });
  return res;
}

export const LOGIN_USER_INFO = createRequestTypes("LOGIN_USER_INFO");
export const SIGN_UP_USER = createRequestTypes("SIGN_UP_USER");
export const GET_ALL_USER = createRequestTypes("GET_ALL_USER");
export const ADD_NEW_LINK = createRequestTypes("ADD_NEW_LINK");
export const GET_LINK_LIST = createRequestTypes("GET_LINK_LIST");

export const SIGN_UP_CLEAR = "SIGN_UP_CLEAR";

export const LOGOUT = "LOGOUT";
